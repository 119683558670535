import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import mixpanel from 'mixpanel-browser';
import { Formik } from 'formik';
import * as Yup from 'yup';
import bsCustomFileInput from 'bs-custom-file-input';
import { Row, Col, Form, Alert } from 'react-bootstrap';
import Select from 'react-select'
import { useTranslation } from 'react-i18next';

import MultiInput from '../customInputs/MultiInput'
import wolData from '../../helpers/wolData';
import utils from '../../helpers/utils';
import WolSpinner from '../commons/WolSpinner';
import WolFormAlert from '../commons/WoLFormAlert';
import CustomFlowTitle from '../profile/CustomFlowTitle';
import CustomFlowSubmit from '../profile/CustomFlowSubmit';

export const getProfileUrl = ( candidateId, applyingToJobId ) => {
  if( !(candidateId) ){ return null };
  const url = `${wolData.urls.APIUrl}/candidates/${candidateId}/get_profile`
  if(applyingToJobId){
    return `${url}?applying_to_job_id=${applyingToJobId}`;
  } else {
    return url;
  }
}

export const GraduationYearShowMeTheMoneyLabel = ({ flow, notGraduated }) => {
  if (flow !== 'salary' || notGraduated !== true) { return null };
  return (
    <Alert
      variant='warning'
      style={{ marginTop: '10px', fontSize: '14px' }}>
      Si ya egresaste de la universidad es importante que actualices tu <strong>año de egreso</strong>, de esta forma podemos engregarte resultados personalizados y ajustados a tu perfil.
    </Alert>
  )
}

export const checkNotGraduated = (data) => {
  // TO DO: Add '-1' as string if checkNotGraduated is used with university_graduation_year field selector
  return(
    !(data) ||
    !(data.initial_values) ||
    !(data.initial_values.university_graduation_year) ||
    (data.initial_values.university_graduation_year === -1)
  )
}

export const checkJunior = (data) => {
  if (
    data &&
    data.initial_values &&
    data.initial_values.university_graduation_year &&
    ((new Date()).getFullYear() - data.initial_values.university_graduation_year) >= 7
  ){
    return false;
  } else {
    return true;
  }
}

// TO DO: Add tests
const UniversityGradesField = ({ t, handleBlur, universityGradesInitialValues, setFieldValue }) => {
  const description = 'No es un campo obligatorio. Sin embargo, algunos empleadores no consideran las postulaciones que vienen sin las notas.';
  return (
    <Form.Group>
      <Form.Label className='mb-4'>
        {t('candidateProfileForm.universityGradesLabel')}<br></br>
        <small>{description}</small>
      </Form.Label>
      <Form.File
        name="university_grades"
        type="file"
        onBlur={handleBlur}
        onChange={(event) => { setFieldValue("university_grades", event.currentTarget.files[0]) }}
      />
      {utils.displayFileData(universityGradesInitialValues)}
    </Form.Group>
  )
}

const ProfileForm = ({ formFlow }) => {
  const { t } = useTranslation("global");

  // Set Candidate Profile Initial Data
  const [isNotGraduated, setIsNotGraduated] = useState(false)
  const [isJunior, setIsJunior] = useState(true)
  const [candidate, setCandidate] = useState(null);
  const [applicationData, setApplicationData] = useState(null);
  const [fieldDefaultValues, setFieldDefaultValues] = useState(null);
  const [universityGradesInitialValues, setUniversityGradesInitialValues] = useState(null);
  const [isCandidateLoaded, setIsCandidateLoaded] = useState(false);

  const getValidationSchema = (userSite) => {
    const validationObject = {
      university_id: Yup.string().required(t('candidateProfileForm.errorMessages.universityId')),
      university_graduation_year: Yup.string().required(t('candidateProfileForm.errorMessages.universityGraduationYear')),
      primary_speciality: Yup.string().required("Ingrese área principal"),
      english_level: Yup.string().required("Ingrese nivel de inglés")
    }

    // University ranking and university ranking students total are not required when candidate is student yet
    if (userSite === 'CL') {
      if (applicationData.candidate_university_graduation_year &&
        applicationData.candidate_university_graduation_year !== -1 &&
        isJunior) {

        validationObject['university_ranking'] = Yup.number()
          .required("Ingrese Ranking N°")
          .min(1, "Ingrese un número mayor que 0")
          .max(500, "El máximo es 500")
        validationObject['university_ranking_students_total'] = Yup.number()
          .required("Ingrese De un total de")
          .min(1, "Ingrese un número mayor que 0")
          .max(500, "El máximo es 500")
      }

    } else if (userSite === 'CO' && (isJunior || isNotGraduated)) {
      validationObject["university_average_grade"] = Yup.number()
        .required("Ingrese calificación promedio de la universidad")
        .min(1, "Ingrese un número mayor que 0")
        .max(5, "El máximo es 5")

    }
    return Yup.object().shape(validationObject);
  }

  useEffect(() => {
    bsCustomFileInput.init();

    const profileUrl = getProfileUrl(
      localStorage.getItem('candidateId'),
      localStorage.getItem('applyingToJobId')
    )
    const request = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('jwt')
      }
    }

    fetch( profileUrl, request ).then((response) => {
      return response.json()

    }).then((data) => {
      if (data.errors) {
        if (data.errors.invalid_token) {
          localStorage.removeItem('jwt');
          window.location.href = wolData.urls.frontUrl + "/sign-in";
        }
      } else {
        setCandidate(data.initial_values)
        setApplicationData(data.application_data)
        setFieldDefaultValues(data.fields_default_values)
        setIsNotGraduated( checkNotGraduated(data) )
        setIsJunior( checkJunior(data) )
        setUniversityGradesInitialValues(data.university_grades_initial_values)
        setIsCandidateLoaded(true)
        mixpanel.track('Profile view')
      }
    }).catch((errors) => {
      console.error(errors)
    })
  }, []);

  const candidateProfileFormOnSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
    setSubmitting(true);
    setTimeout(() => {
      setSubmitting(false);
    }, 500);

    let data = new FormData();
    data.append("university_id", values.university_id)
    data.append("university_graduation_year", values.university_graduation_year)
    data.append("university_ranking", values.university_ranking)
    data.append("university_ranking_students_total", values.university_ranking_students_total)
    data.append("university_average_grade", values.university_average_grade)
    data.append("english_level", values.english_level)

    if (values.university_grades && values.university_grades.name) { data.append("university_grades", values.university_grades) }
    if (values.postgraduate_id) { data.append("postgraduate_id", values.postgraduate_id) }
    if (values.postgraduate_type_id) {
      data.append("postgraduate_type_id", values.postgraduate_type_id)
      if (values.postgraduate_university) { data.append("postgraduate_university_id", values.postgraduate_university.value) }
      if (values.postgraduate_fantasy_name) { data.append("postgraduate_fantasy_name", values.postgraduate_fantasy_name) }
      if (values.postgraduate_other_university) { data.append("postgraduate_other_university", values.postgraduate_other_university) }
    }

    const normalizedSpecialities = utils.candidateSpecialitiesAttributesRequestObject(values.primary_speciality, values.other_specialities)
    data.append("candidate_specialities_attributes", JSON.stringify(normalizedSpecialities))

    const requestOptions = {
      method: 'put',
      headers: { 'Accept': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('jwt') },
      body: data
    };

    const profileFormUrl = wolData.urls.APIUrl + "/candidates/" + localStorage.getItem("candidateId") + "/profile";

    fetch(profileFormUrl, requestOptions)
      .then((response) => {
        return response.json()
      })
      .then((data) => {

        if (data.errors) {
          if (data.errors.invalid_token) {
            localStorage.removeItem('jwt');
            window.location.href = wolData.urls.frontUrl + "/sign-in";
          }

          // GA Tracking: Profile 1 with errors
          // Track after invalid token redirection
          ReactGA.event({ category: 'candidate', action: 'profile1-with-errors' });

          if (data.errors['candidate_postgraduates.postgraduate_type']) {
            setStatus({ postgraduate_type_id: "Si agregas una universidad de post-grado debes agregar un postgrado" })
          }

        } else {
          // GA Tracking: Profile 1 completed successfully
          ReactGA.event({ category: 'candidate', action: 'profile1-completed-successfully' });
          mixpanel.track('Profile 1 changes saved')

          // TODO: Use history instead window.location
          if (formFlow === 'application') {
            if (localStorage.getItem("applyingToJobId") === null) {
              window.location.href = wolData.urls.frontUrl + "/jobs";
            } else {
              window.location.href = wolData.urls.frontUrl + "/apply-to?job_id=" + localStorage.getItem("applyingToJobId") + "&candidate_id=" + localStorage.getItem("candidateId");
            }

          } else if (formFlow === 'salary') {
            window.location.href = wolData.urls.frontUrl + '/show-me-the-money';

          } else {
            setStatus({ successFormSubmission: true })
            if (data.university_grades_initial_values.filename && data.university_grades_initial_values.url) {
              setUniversityGradesInitialValues(data.university_grades_initial_values)
            }
            setTimeout(() => { setStatus({ successFormSubmission: false }) }, 6000);
          }
        }
      })
      .catch((errors) => {
        console.error(errors)
      });
  }

  return !isCandidateLoaded ? (
    <WolSpinner />
  ) : (
    <Formik
      initialValues={candidate}
      validationSchema={getValidationSchema(localStorage.getItem('userSite'))}
      onSubmit={candidateProfileFormOnSubmit}
    >
      {({ values,
        errors,
        status,
        touched,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        handleChange,
        handleBlur,
        handleSubmit }) => {

        return (
          <Row>
            <Col>
              <CustomFlowTitle formFlow={formFlow} />

              <Form onSubmit={handleSubmit} className='wol-form'>
                <input
                  type="hidden"
                  name="postgraduate_id"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.postgraduate_id}
                />

                <h2 className='mb-5' style={{ fontWeight: 500 }}>Formación Académica</h2>

                <Form.Group className="form-group-custom">
                  <Form.Label>{t('candidateProfileForm.universityIdLabel')} *</Form.Label>

                  <Select
                    name='university_id'
                    isClearable={true}
                    value={fieldDefaultValues.universities.find(option => option.value === values.university_id)}
                    options={fieldDefaultValues.universities}
                    onChange={(option) => {setFieldValue('university_id', option.value)}}
                    onBlur={setFieldTouched}
                    placeholder={"Selecciona universidad"}
                    className={utils.fieldClassName('university_id', status, touched, errors)}
                  />

                  {utils.displayFieldError('university_id', status, touched, errors)}
                </Form.Group>

                <Form.Group className="form-group-custom">
                  <Form.Label>
                    {t('candidateProfileForm.universityGraduationYearLabel')} *
                    <GraduationYearShowMeTheMoneyLabel flow={formFlow} notGraduated={isNotGraduated} />
                  </Form.Label>
                  <Form.Control
                    name="university_graduation_year"
                    as="select"
                    onBlur={handleBlur}
                    value={values.university_graduation_year}
                    className={utils.fieldClassName('university_graduation_year', status, touched, errors)}
                    onChange={(e) => {
                      setFieldValue("university_graduation_year", e.target.value)
                      if (e.target.value === '-1') {
                        setIsNotGraduated(true)
                      } else {
                        setIsNotGraduated(false)
                      }
                      if (((new Date()).getFullYear() - e.target.value) >= 7) {
                        setIsJunior(false)
                      } else {
                        setIsJunior(true)
                      }
                    }}
                  >
                    {utils.universityGraduationYearDefaultValues(new Date().getFullYear() - 60, new Date().getFullYear())}
                  </Form.Control>
                  {utils.displayFieldError('university_graduation_year', status, touched, errors)}
                </Form.Group>

                {/* Dynamic educational fields */}
                {
                  // if site CO
                  // TO DO: Add university grades file?
                  ((localStorage.getItem('userSite') === 'CO') && (isJunior || isNotGraduated)) ?
                    <Form.Group className="form-group-custom">
                      <Form.Label>{t('candidateProfileForm.universityPerformanceLabel')} *</Form.Label>
                      <Form.Control
                        name="university_average_grade"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.university_average_grade}
                        as="select"
                        className={utils.fieldClassName('university_average_grade', status, touched, errors)}
                      >
                        <option key=""></option>
                        {wolData.colombianUniversityAverageGradeValues.map((uag) => (
                          <option key={uag.toString()}>
                            {uag}
                          </option>
                        ))}
                      </Form.Control>
                      {utils.displayFieldError('university_average_grade', status, touched, errors)}
                    </Form.Group>

                    : // else CO

                    // if site CL
                    (localStorage.getItem('userSite') === 'CL') ?

                      // if graduated
                      // TO DO: Fix values.university_graduation_year -1 not always is same to isNotGraduated
                      (values.university_graduation_year === '-1' || values.university_graduation_year === -1) ?
                        (
                          <UniversityGradesField
                            t={t}
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            universityGradesInitialValues={universityGradesInitialValues} />
                        )

                        :

                        (
                          (isNotGraduated || !(isJunior)) ? null :
                            <React.Fragment>
                              <Form.Group className="form-group-custom">
                                <Form.Label>{t('candidateProfileForm.universityPerformanceLabel')} *</Form.Label>
                                <Form.Row>
                                  <Col>
                                    <Form.Label>Ranking N°</Form.Label>
                                    <Form.Control
                                      name="university_ranking"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.university_ranking}
                                      type="number"
                                      className={utils.fieldClassName('university_ranking', status, touched, errors)}
                                    />
                                    {utils.displayFieldError('university_ranking', status, touched, errors)}
                                  </Col>
                                  <Col>
                                    <Form.Label >De un total de</Form.Label>
                                    <Form.Control
                                      name="university_ranking_students_total"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.university_ranking_students_total}
                                      type="number"
                                      className={utils.fieldClassName('university_ranking_students_total', status, touched, errors)}
                                    />
                                    {utils.displayFieldError('university_ranking_students_total', status, touched, errors)}
                                  </Col>
                                </Form.Row>
                              </Form.Group>

                              <UniversityGradesField
                                t={t}
                                handleBlur={handleBlur}
                                setFieldValue={setFieldValue}
                                universityGradesInitialValues={universityGradesInitialValues} />
                            </React.Fragment>
                        )


                      : // else site CL

                      // if site MX
                      ((localStorage.getItem('userSite') === 'MX') && (isJunior || isNotGraduated)) ?
                        <React.Fragment>
                          <Form.Group className="form-group-custom">
                            <Form.Label>{t('candidateProfileForm.universityPerformanceLabel')}</Form.Label>
                            <Form.Control
                              name="university_average_grade"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.university_average_grade}
                              as="select"
                              className={utils.fieldClassName('university_average_grade', status, touched, errors)}
                            >
                              <option key=""></option>
                              {wolData.mexicanUniversityAverageGradeValues.map((uag) => (<option key={uag.toString()}>{uag}</option>))}
                            </Form.Control>
                            {utils.displayFieldError('university_average_grade', status, touched, errors)}
                          </Form.Group>

                          <UniversityGradesField
                            t={t}
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            universityGradesInitialValues={universityGradesInitialValues}/>
                        </React.Fragment> : // else site MX

                        null
                }

                {(isNotGraduated) ? null :
                  <React.Fragment>

                    <Form.Group className="form-group-custom">
                      <Form.Label>
                        ¿Tienes algún estudio de post-grado?<br></br>
                        <small>Escoge la que mejor describan tu situación</small>
                      </Form.Label>
                      <Form.Control
                        name="postgraduate_type_id"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.postgraduate_type_id || ""}
                        as="select"
                        className={utils.fieldClassName('postgraduate_type_id', status, touched, errors)}
                      >
                        {wolData.postgraduateTypes.map((pt) => (
                          <option key={pt.value} value={pt.value}>
                            {pt.label}
                          </option>
                        ))}
                      </Form.Control>
                      {utils.displayFieldError('postgraduate_type_id', status, touched, errors)}
                    </Form.Group>

                    {values.postgraduate_type_id ? (
                      <React.Fragment>
                        <Form.Group className="form-group-custom">
                          <Form.Label>Título de posgrado</Form.Label>
                          <Form.Control
                            name="postgraduate_fantasy_name"
                            as='textarea'
                            maxLength='50'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.postgraduate_fantasy_name}
                            className={utils.fieldClassName('postgraduate_fantasy_name', status, touched, errors)}
                          ></Form.Control>
                          {utils.displayFieldError('postgraduate_fantasy_name', status, touched, errors)}
                        </Form.Group>

                        <Form.Group className="form-group-custom">
                          <Form.Label>¿Fue en alguna de las siguientes universidades?</Form.Label>
                          <Select
                            name="postgraduate_university"
                            value={values.postgraduate_university}
                            options={fieldDefaultValues.postgraduate_universities}
                            onBlur={() => { setFieldTouched("postgraduate_university", true) }}
                            className={utils.fieldClassName('postgraduate_university', status, touched, errors)}
                            onChange={(option) => {
                              setFieldValue("postgraduate_university", option)
                            }}
                          />
                          {utils.displayFieldError('postgraduate_university', status, touched, errors)}
                        </Form.Group>

                        {(values.postgraduate_university && values.postgraduate_university.label === "Otra") ? (
                          <Form.Group className="form-group-custom">
                            <Form.Label>Ingresa el nombre de tu universidad</Form.Label>
                            <Form.Control
                              name="postgraduate_other_university"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.postgraduate_other_university}
                              className={utils.fieldClassName('postgraduate_other_university', status, touched, errors)}
                            />
                            {utils.displayFieldError('postgraduate_other_university', status, touched, errors)}
                          </Form.Group>

                        ) : null}
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                }

                <Form.Group className="form-group-custom">
                  <Form.Label>
                    ¿Cuál es tu nivel de inglés? *<br></br>
                    <small>Te recomendamos seleccionar un nivel de inglés que puedas demostrar.</small>
                  </Form.Label>
                  <Form.Control
                    name="english_level"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.english_level}
                    as="select"
                    className={utils.fieldClassName('english_level', status, touched, errors)}
                  >
                    {wolData.englishLevels.map((el) => (
                      <option key={el.value} value={el.value}>
                        {el.label}
                      </option>
                    ))}
                  </Form.Control>
                  {utils.displayFieldError('english_level', status, touched, errors)}
                </Form.Group>

                <Form.Group className="form-group-custom">
                  <Form.Label>
                    ¿Cuál es tu área principal? *<br></br>
                    <small>Área de interés principal, en caso que no tengas experiencia</small>
                  </Form.Label>
                  <Form.Control
                    name="primary_speciality"
                    as="select"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.primary_speciality}
                    className={utils.fieldClassName('primary_speciality', status, touched, errors)}
                  >
                    <option></option>
                    {(values.other_specialities ? (
                      fieldDefaultValues.specialities
                        .filter(s => {
                          return !values.other_specialities.some(os => os.label === s.label)
                        })
                    ) : (
                      fieldDefaultValues.specialities
                    ))
                      .map((s) => (
                        <option key={s.value} value={s.value}>
                          {s.label}
                        </option>
                      ))}
                  </Form.Control>
                  {utils.displayFieldError('primary_speciality', status, touched, errors)}
                </Form.Group>

                <Form.Group className="form-group-custom">
                  <Form.Label>{t('candidateProfileForm.otherSpecialitiesLabel')}</Form.Label>
                  <MultiInput
                    name={"other_specialities"}
                    options={fieldDefaultValues.specialities.filter((s) => s.value.toString() !== values.primary_speciality)}
                    value={values.other_specialities}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={errors.other_specialities}
                    touched={touched.other_specialities}
                  />
                </Form.Group>

                <WolFormAlert
                  status={status}
                  errors={errors}
                  errorMessageKey={'candidateProfileError'}
                  successMessageKey={'candidateProfileSuccess'}
                />

                <CustomFlowSubmit
                  formFlow={formFlow}
                  profile={'profile-1'}
                  isSubmitting={isSubmitting}
                />
              </Form>
            </Col>
          </Row>
        )
      }}
    </Formik>
  )
}

export default ProfileForm;