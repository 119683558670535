const wolData = {

  validSites: ['CL', 'CO', 'MX'],

  urls: {
    APIUrl: process.env.REACT_APP_WOL_API_URL,
    frontUrl: process.env.REACT_APP_WOL_FRONT_URL,
    homepageUrl: process.env.REACT_APP_HOMEPAGE_URL,
    workplacesUrl: process.env.REACT_APP_WORKPLACES_URL,
    signIn: `${process.env.REACT_APP_WOL_FRONT_URL}/sign-in`,
    signUp: `${process.env.REACT_APP_WOL_FRONT_URL}/sign-up`,
    jobs: `${process.env.REACT_APP_WOL_FRONT_URL}/jobs`,
    profile: `${process.env.REACT_APP_WOL_FRONT_URL}/profile`,
    showMeTheMoney: `${process.env.REACT_APP_WOL_FRONT_URL}/show-me-the-money`,
    applicationAndCareer: `${process.env.REACT_APP_WOL_FRONT_URL}/postulacion-y-carrera`,
    changePassword: `${process.env.REACT_APP_WOL_FRONT_URL}/change-password`,
    blog: 'https://blog.workon.law'
  },

  genderOptions: [
    { value: 1, label: 'Hombre' },
    { value: 2, label: 'Mujer' },
    { value: 3, label: 'Otro' },
    { value: 4, label: 'Prefiero no responder' }
  ],

  positions: [
    { value: '', label: '' },
    { value: '1', label: 'General Manager' },
    { value: '2', label: 'Law Clerk' },
    { value: '3', label: 'Paralegal' },
    { value: '4', label: 'Seasonal internship' },
    { value: '5', label: 'Associate' },
    { value: '6', label: 'Counsel (Of Counsel)' },
    { value: '7', label: 'Director' },
    { value: '8', label: 'External Counsel' },
    { value: '9', label: 'General Counsel' },
    { value: '10', label: 'Foreign Associate' },
    { value: '11', label: 'Junior Associate' },
    { value: '12', label: 'Legal Counsel' },
    { value: '13', label: 'Managing Partner' },
    { value: '14', label: 'Non-equity Partner' },
    { value: '15', label: 'Partner' },
    { value: '16', label: 'Senior Associate' },
    { value: '17', label: 'Senior Partner' },
    { value: '18', label: 'Law Professor' }
  ],

  // positionsSpanish: [
  //   { value: '', label: '' },
  //   { value: '1', label: 'Gerente General' },
  //   { value: '2', label: 'Procurador/a' },
  //   { value: '3', label: 'Paralegal' },
  //   { value: '4', label: 'Pasantía de verano' },
  //   { value: '5', label: 'Asociado/a' },
  //   { value: '6', label: 'Of Counsel' },
  //   { value: '7', label: 'Director' },
  //   { value: '8', label: 'External Counsel' },
  //   { value: '9', label: 'Gerente Legal' },
  //   { value: '10', label: 'Foreign Associate' },
  //   { value: '11', label: 'Asociado/a Junior' },
  //   { value: '12', label: 'Legal Counsel' },
  //   { value: '13', label: 'Socio/a Administrador' },
  //   { value: '14', label: 'Socio/a non-equity' },
  //   { value: '15', label: 'Socio/a' },
  //   { value: '16', label: 'Asociado/a Senior' },
  //   { value: '17', label: 'Senior Partner' },
  //   { value: '18', label: 'Profesor Universitario' }
  // ],

  postgraduateTypes: [
    { value: '', label: '' },
    { value: '1', label: 'Diplomado' },
    { value: '2', label: 'Máster' },
    { value: '3', label: 'LL.M en país anglosajón' },
    { value: '4', label: 'LL.M en otro país' },
    { value: '5', label: 'MBA nacional' },
    { value: '6', label: 'MBA en otro país' }
  ],

  englishLevels: [
    { value: '', label: '' },
    { value: '1', label: 'Nativo o Bilingüe (sobre 95%)' },
    { value: '2', label: 'Fluido (90% a 94%)' },
    { value: '3', label: 'Avanzado (80% a 89%)' },
    { value: '4', label: 'Intermedio avanzado (70% a 79%)' },
    { value: '5', label: 'Intermedio (40% a 69%)' },
    { value: '6', label: 'Básico (menos de 40%)' }
  ],

  drivers: [
    { value: '', label: '' },
    { value: '1', label: 'Mejorar condiciones laborales' },
    { value: '2', label: 'Volviendo del extranjero' },
    { value: '3', label: 'Sin trabajo' },
    { value: '4', label: 'Explorar nueva área de práctica' },
    { value: '5', label: 'Cambiar de jornada laboral' },
    { value: '6', label: 'Cambio de ciudad' },
    { value: '7', label: 'Mejorar el sueldo' },
    { value: '8', label: 'No estoy buscando trabajo. Quiero consultar la herramienta de sueldos de Work On Law' }
  ],

  colombianUniversityAverageGradeValues: [
    1, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9,
    2, 2.1, 2.2, 2.3, 2.4, 2.5, 2.6, 2.7, 2.8, 2.9,
    3, 3.1, 3.2, 3.3, 3.4, 3.5, 3.6, 3.7, 3.8, 3.9,
    4, 4.1, 4.2, 4.3, 4.4, 4.5, 4.6, 4.7, 4.8, 4.9, 5
  ],

  mexicanUniversityAverageGradeValues: [
    1, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9,
    2, 2.1, 2.2, 2.3, 2.4, 2.5, 2.6, 2.7, 2.8, 2.9,
    3, 3.1, 3.2, 3.3, 3.4, 3.5, 3.6, 3.7, 3.8, 3.9,
    4, 4.1, 4.2, 4.3, 4.4, 4.5, 4.6, 4.7, 4.8, 4.9,
    5, 5.1, 5.2, 5.3, 5.4, 5.5, 5.6, 5.7, 5.8, 5.9,
    6, 6.1, 6.2, 6.3, 6.4, 6.5, 6.6, 6.7, 6.8, 6.9,
    7, 7.1, 7.2, 7.3, 7.4, 7.5, 7.6, 7.7, 7.8, 7.9,
    8, 8.1, 8.2, 8.3, 8.4, 8.5, 8.6, 8.7, 8.8, 8.9,
    9, 9.1, 9.2, 9.3, 9.4, 9.5, 9.6, 9.7, 9.8, 9.9, 10
  ],

  chileanSalaries: [
    { value: "100000", label: "$100.000" },
    { value: "150000", label: "$150.000" },
    { value: "200000", label: "$200.000" },
    { value: "250000", label: "$250.000" },
    { value: "300000", label: "$300.000" },
    { value: "350000", label: "$350.000" },
    { value: "400000", label: "$400.000" },
    { value: "450000", label: "$450.000" },
    { value: "500000", label: "$500.000" },
    { value: "550000", label: "$550.000" },
    { value: "600000", label: "$600.000" },
    { value: "650000", label: "$650.000" },
    { value: "700000", label: "$700.000" },
    { value: "750000", label: "$750.000" },
    { value: "800000", label: "$800.000" },
    { value: "850000", label: "$850.000" },
    { value: "900000", label: "$900.000" },
    { value: "950000", label: "$950.000" },
    { value: "1000000", label: "$1.000.000" },
    { value: "1100000", label: "$1.100.000" },
    { value: "1200000", label: "$1.200.000" },
    { value: "1300000", label: "$1.300.000" },
    { value: "1400000", label: "$1.400.000" },
    { value: "1500000", label: "$1.500.000" },
    { value: "1600000", label: "$1.600.000" },
    { value: "1700000", label: "$1.700.000" },
    { value: "1800000", label: "$1.800.000" },
    { value: "1900000", label: "$1.900.000" },
    { value: "2000000", label: "$2.000.000" },
    { value: "2100000", label: "$2.100.000" },
    { value: "2200000", label: "$2.200.000" },
    { value: "2300000", label: "$2.300.000" },
    { value: "2400000", label: "$2.400.000" },
    { value: "2500000", label: "$2.500.000" },
    { value: "2600000", label: "$2.600.000" },
    { value: "2700000", label: "$2.700.000" },
    { value: "2800000", label: "$2.800.000" },
    { value: "2900000", label: "$2.900.000" },
    { value: "3000000", label: "$3.000.000" },
    { value: "3100000", label: "$3.100.000" },
    { value: "3200000", label: "$3.200.000" },
    { value: "3300000", label: "$3.300.000" },
    { value: "3400000", label: "$3.400.000" },
    { value: "3500000", label: "$3.500.000" },
    { value: "3600000", label: "$3.600.000" },
    { value: "3700000", label: "$3.700.000" },
    { value: "3800000", label: "$3.800.000" },
    { value: "3900000", label: "$3.900.000" },
    { value: "4000000", label: "$4.000.000" },
    { value: "4100000", label: "$4.100.000" },
    { value: "4200000", label: "$4.200.000" },
    { value: "4300000", label: "$4.300.000" },
    { value: "4400000", label: "$4.400.000" },
    { value: "4500000", label: "$4.500.000" },
    { value: "4600000", label: "$4.600.000" },
    { value: "4700000", label: "$4.700.000" },
    { value: "4800000", label: "$4.800.000" },
    { value: "4900000", label: "$4.900.000" },
    { value: "5000000", label: "$5.000.000" },
    { value: "5250000", label: "$5.250.000" },
    { value: "5500000", label: "$5.500.000" },
    { value: "5750000", label: "$5.750.000" },
    { value: "6000000", label: "$6.000.000" },
    { value: "6250000", label: "$6.250.000" },
    { value: "6500000", label: "$6.500.000" },
    { value: "6750000", label: "$6.750.000" },
    { value: "7000000", label: "$7.000.000" },
    { value: "7250000", label: "$7.250.000" },
    { value: "7500000", label: "$7.500.000" },
    { value: "7750000", label: "$7.750.000" },
    { value: "8000000", label: "$8.000.000" },
    { value: "8250000", label: "$8.250.000" },
    { value: "8500000", label: "$8.500.000" },
    { value: "8750000", label: "$8.750.000" },
    { value: "9000000", label: "$9.000.000" },
    { value: "9250000", label: "$9.250.000" },
    { value: "9500000", label: "$9.500.000" },
    { value: "9750000", label: "$9.750.000" },
    { value: "10000000", label: "$10.000.000" },
    { value: "10250000", label: "$10.250.000" },
    { value: "10500000", label: "$10.500.000" },
    { value: "10750000", label: "$10.750.000" },
    { value: "11000000", label: "$11.000.000" },
    { value: "11250000", label: "$11.250.000" },
    { value: "11500000", label: "$11.500.000" },
    { value: "11750000", label: "$11.750.000" },
    { value: "12000000", label: "$12.000.000" },
    { value: "12250000", label: "$12.250.000" },
    { value: "12500000", label: "$12.500.000" },
    { value: "12750000", label: "$12.750.000" },
    { value: "13000000", label: "$13.000.000" },
    { value: "13250000", label: "$13.250.000" },
    { value: "13500000", label: "$13.500.000" },
    { value: "13750000", label: "$13.750.000" },
    { value: "14000000", label: "$14.000.000" },
    { value: "14250000", label: "$14.250.000" },
    { value: "14500000", label: "$14.500.000" },
    { value: "14750000", label: "$14.750.000" },
    { value: "15000000", label: "$15.000.000" },
    { value: "15250000", label: "$15.250.000" },
    { value: "15500000", label: "$15.500.000" },
    { value: "15750000", label: "$15.750.000" },
    { value: "16000000", label: "$16.000.000" },
    { value: "16250000", label: "$16.250.000" },
    { value: "16500000", label: "$16.500.000" },
    { value: "16750000", label: "$16.750.000" },
    { value: "17000000", label: "$17.000.000" },
    { value: "17250000", label: "$17.250.000" },
    { value: "17500000", label: "$17.500.000" },
    { value: "17750000", label: "$17.750.000" },
    { value: "18000000", label: "$18.000.000" },
    { value: "18250000", label: "$18.250.000" },
    { value: "18500000", label: "$18.500.000" },
    { value: "18750000", label: "$18.750.000" },
    { value: "19000000", label: "$19.000.000" },
    { value: "19250000", label: "$19.250.000" },
    { value: "19590000", label: "$19.500.000" },
    { value: "19750000", label: "$19.750.000" },
    { value: "20000000", label: "$20.000.000" },
    { value: "20250000", label: "$20.250.000" },
    { value: "20500000", label: "$20.500.000" },
    { value: "20750000", label: "$20.750.000" },
    { value: "21000000", label: "$21.000.000" },
    { value: "21500000", label: "$21.500.000" },
    { value: "22000000", label: "$22.000.000" },
    { value: "22500000", label: "$22.500.000" },
    { value: "23000000", label: "$23.000.000" },
    { value: "23500000", label: "$23.500.000" },
    { value: "24000000", label: "$24.000.000" },
    { value: "24500000", label: "$24.500.000" },
    { value: "25000000", label: "$25.000.000" },
    { value: "25000001", label: "Más de 25.000.000" }
  ],

  colombianSalaries: [
    { value: "500000", label: "$500.000" },
    { value: "750000", label: "$750.000" },
    { value: "1000000", label: "$1000.000" },
    { value: "1250000", label: "$1.250.000" },
    { value: "1500000", label: "$1.500.000" },
    { value: "1750000", label: "$1.750.000" },
    { value: "2000000", label: "$2.000.000" },
    { value: "2250000", label: "$2.250.000" },
    { value: "2500000", label: "$2.500.000" },
    { value: "2750000", label: "$2.750.000" },
    { value: "3000000", label: "$3.000.000" },
    { value: "3250000", label: "$3.250.000" },
    { value: "3500000", label: "$3.500.000" },
    { value: "3750000", label: "$3.750.000" },
    { value: "4000000", label: "$4.000.000" },
    { value: "4250000", label: "$4.250.000" },
    { value: "4500000", label: "$4.500.000" },
    { value: "4750000", label: "$4.750.000" },
    { value: "5000000", label: "$5.000.000" },
    { value: "5250000", label: "$5.250.000" },
    { value: "5500000", label: "$5.500.000" },
    { value: "5750000", label: "$5.750.000" },
    { value: "6000000", label: "$6.000.000" },
    { value: "6250000", label: "$6.250.000" },
    { value: "6500000", label: "$6.500.000" },
    { value: "6750000", label: "$6.750.000" },
    { value: "7000000", label: "$7.000.000" },
    { value: "7250000", label: "$7.250.000" },
    { value: "7500000", label: "$7.500.000" },
    { value: "7750000", label: "$7.750.000" },
    { value: "8000000", label: "$8.000.000" },
    { value: "8250000", label: "$8.250.000" },
    { value: "8500000", label: "$8.500.000" },
    { value: "9750000", label: "$9.750.000" },
    { value: "10000000", label: "$10.000.000" },
    { value: "10500000", label: "$10.500.000" },
    { value: "11000000", label: "$11.000.000" },
    { value: "11500000", label: "$11.500.000" },
    { value: "12000000", label: "$12.000.000" },
    { value: "12500000", label: "$12.500.000" },
    { value: "13000000", label: "$13.000.000" },
    { value: "13500000", label: "$13.500.000" },
    { value: "14000000", label: "$14.000.000" },
    { value: "14500000", label: "$14.500.000" },
    { value: "15000000", label: "$15.000.000" },
    { value: "15500000", label: "$15.500.000" },
    { value: "16000000", label: "$16.000.000" },
    { value: "16500000", label: "$16.500.000" },
    { value: "17000000", label: "$17.000.000" },
    { value: "17500000", label: "$17.500.000" },
    { value: "18000000", label: "$18.000.000" },
    { value: "18500000", label: "$18.500.000" },
    { value: "19000000", label: "$19.000.000" },
    { value: "19500000", label: "$19.500.000" },
    { value: "20000000", label: "$20.000.000" },
    { value: "20500000", label: "$20.500.000" },
    { value: "21000000", label: "$21.000.000" },
    { value: "21500000", label: "$21.500.000" },
    { value: "22000000", label: "$22.000.000" },
    { value: "22500000", label: "$22.500.000" },
    { value: "23000000", label: "$23.000.000" },
    { value: "23500000", label: "$23.500.000" },
    { value: "24000000", label: "$24.000.000" },
    { value: "24500000", label: "$24.500.000" },
    { value: "25000000", label: "$25.000.000" },
    { value: "25500000", label: "$25.500.000" },
    { value: "26000000", label: "$26.000.000" },
    { value: "26500000", label: "$26.500.000" },
    { value: "27000000", label: "$27.000.000" },
    { value: "27500000", label: "$27.500.000" },
    { value: "28000000", label: "$28.000.000" },
    { value: "28500000", label: "$28.500.000" },
    { value: "29000000", label: "$29.000.000" },
    { value: "29500000", label: "$29.500.000" },
    { value: "30000000", label: "$30.000.000" },
    { value: "30500000", label: "$30.500.000" },
    { value: "31000000", label: "$31.000.000" },
    { value: "31500000", label: "$31.500.000" },
    { value: "32000000", label: "$32.000.000" },
    { value: "32500000", label: "$32.500.000" },
    { value: "33000000", label: "$33.000.000" },
    { value: "33500000", label: "$33.500.000" },
    { value: "34000000", label: "$34.000.000" },
    { value: "34500000", label: "$34.500.000" },
    { value: "35000000", label: "$35.000.000" },
    { value: "35500000", label: "$35.500.000" },
    { value: "36000000", label: "$36.000.000" },
    { value: "36500000", label: "$36.500.000" },
    { value: "37000000", label: "$37.000.000" },
    { value: "37500000", label: "$37.500.000" },
    { value: "38000000", label: "$38.000.000" },
    { value: "38500000", label: "$38.500.000" },
    { value: "39000000", label: "$39.000.000" },
    { value: "39500000", label: "$39.500.000" },
    { value: "40000000", label: "$40.000.000" },
    { value: "40500000", label: "$40.500.000" },
    { value: "41000000", label: "$41.000.000" },
    { value: "41500000", label: "$41.500.000" },
    { value: "42000000", label: "$42.000.000" },
    { value: "42500000", label: "$42.500.000" },
    { value: "43000000", label: "$43.000.000" },
    { value: "43500000", label: "$43.500.000" },
    { value: "44000000", label: "$44.000.000" },
    { value: "44500000", label: "$44.500.000" },
    { value: "45000000", label: "$45.000.000" },
    { value: "45500000", label: "$45.500.000" },
    { value: "46000000", label: "$46.000.000" },
    { value: "46500000", label: "$46.500.000" },
    { value: "47000000", label: "$47.000.000" },
    { value: "47500000", label: "$47.500.000" },
    { value: "48000000", label: "$48.000.000" },
    { value: "48500000", label: "$48.500.000" },
    { value: "49000000", label: "$49.000.000" },
    { value: "49500000", label: "$49.500.000" },
    { value: "50000000", label: "$50.000.000" },
    { value: "50500000", label: "$50.500.000" },
    { value: "51000000", label: "$51.000.000" },
    { value: "51500000", label: "$51.500.000" },
    { value: "52000000", label: "$52.000.000" },
    { value: "52500000", label: "$52.500.000" },
    { value: "53000000", label: "$53.000.000" },
    { value: "53500000", label: "$53.500.000" },
    { value: "54000000", label: "$54.000.000" },
    { value: "54500000", label: "$54.500.000" },
    { value: "55000000", label: "$55.000.000" },
    { value: "55500000", label: "$55.500.000" },
    { value: "56000000", label: "$56.000.000" },
    { value: "56500000", label: "$56.500.000" },
    { value: "57000000", label: "$57.000.000" },
    { value: "57500000", label: "$57.500.000" },
    { value: "58000000", label: "$58.000.000" },
    { value: "58500000", label: "$58.500.000" },
    { value: "59000000", label: "$59.000.000" },
    { value: "59500000", label: "$59.500.000" },
    { value: "60000000", label: "$60.000.000" },
    { value: "61000000", label: "$61.000.000" },
    { value: "62000000", label: "$62.000.000" },
    { value: "63000000", label: "$63.000.000" },
    { value: "64000000", label: "$64.000.000" },
    { value: "65000000", label: "$65.000.000" },
    { value: "66000000", label: "$66.000.000" },
    { value: "67000000", label: "$67.000.000" },
    { value: "68000000", label: "$68.000.000" },
    { value: "69000000", label: "$69.000.000" },
    { value: "70000000", label: "$70.000.000" },
    { value: "71000000", label: "$71.000.000" },
    { value: "72000000", label: "$72.000.000" },
    { value: "73000000", label: "$73.000.000" },
    { value: "74000000", label: "$74.000.000" },
    { value: "75000000", label: "$75.000.000" },
    { value: "76000000", label: "$76.000.000" },
    { value: "77000000", label: "$77.000.000" },
    { value: "78000000", label: "$78.000.000" },
    { value: "79000000", label: "$79.000.000" },
    { value: "80000000", label: "$80.000.000" },
    { value: "81000000", label: "$81.000.000" },
    { value: "82000000", label: "$82.000.000" },
    { value: "83000000", label: "$83.000.000" },
    { value: "84000000", label: "$84.000.000" },
    { value: "85000000", label: "$85.000.000" },
    { value: "86000000", label: "$86.000.000" },
    { value: "87000000", label: "$87.000.000" },
    { value: "88000000", label: "$88.000.000" },
    { value: "89000000", label: "$89.000.000" },
    { value: "90000000", label: "$90.000.000" },
    { value: "90000001", label: "Más de $90.000.000" }
  ],

  mexicanSalaries: [
    { value: "5001", label: "Menos de $7.000" },
    { value: "7000", label: "$7.000" },
    { value: "8000", label: "$8.000" },
    { value: "9000", label: "$9.000" },
    { value: "10000", label: "$10.000" },
    { value: "11000", label: "$11.000" },
    { value: "12000", label: "$12.000" },
    { value: "13000", label: "$13.000" },
    { value: "14000", label: "$14.000" },
    { value: "15000", label: "$15.000" },
    { value: "16000", label: "$16.000" },
    { value: "17000", label: "$17.000" },
    { value: "18000", label: "$18.000" },
    { value: "19000", label: "$19.000" },
    { value: "20000", label: "$20.000" },
    { value: "21000", label: "$21.000" },
    { value: "22000", label: "$22.000" },
    { value: "23000", label: "$23.000" },
    { value: "24000", label: "$24.000" },
    { value: "25000", label: "$25.000" },
    { value: "27500", label: "$27.500" },
    { value: "30000", label: "$30.000" },
    { value: "32500", label: "$32.500" },
    { value: "35000", label: "$35.000" },
    { value: "37500", label: "$37.500" },
    { value: "40000", label: "$42.500" },
    { value: "45000", label: "$45.500" },
    { value: "47500", label: "$47.500" },
    { value: "50000", label: "$50.000" },
    { value: "52500", label: "$52.500" },
    { value: "55000", label: "$55.000" },
    { value: "57500", label: "$57.500" },
    { value: "60000", label: "$60.000" },
    { value: "62500", label: "$62.500" },
    { value: "65000", label: "$65.000" },
    { value: "67500", label: "$67.500" },
    { value: "70000", label: "$70.000" },
    { value: "75000", label: "$75.000" },
    { value: "80000", label: "$80.000" },
    { value: "85000", label: "$85.000" },
    { value: "90000", label: "$90.000" },
    { value: "95000", label: "$95.000" },
    { value: "100000", label: "$100.000" },
    { value: "105000", label: "$105.000" },
    { value: "110000", label: "$110.000" },
    { value: "115000", label: "$115.000" },
    { value: "120000", label: "$120.000" },
    { value: "125000", label: "$125.000" },
    { value: "130000", label: "$130.000" },
    { value: "140000", label: "$140.000" },
    { value: "150000", label: "$150.000" },
    { value: "160000", label: "$160.000" },
    { value: "170000", label: "$170.000" },
    { value: "180000", label: "$180.000" },
    { value: "190000", label: "$190.000" },
    { value: "200000", label: "$200.000" },
    { value: "210000", label: "$210.000" },
    { value: "220000", label: "$220.000" },
    { value: "230000", label: "$230.000" },
    { value: "240000", label: "$240.000" },
    { value: "250000", label: "$250.000" },
    { value: "260000", label: "$260.000" },
    { value: "270000", label: "$270.000" },
    { value: "280000", label: "$280.000" },
    { value: "290000", label: "$290.000" },
    { value: "300000", label: "$300.000" },
    { value: "300000", label: "$300.000" },
    { value: "310000", label: "$310.000" },
    { value: "320000", label: "$320.000" },
    { value: "330000", label: "$330.000" },
    { value: "340000", label: "$340.000" },
    { value: "350000", label: "$350.000" },
    { value: "360000", label: "$360.000" },
    { value: "370000", label: "$370.000" },
    { value: "380000", label: "$380.000" },
    { value: "390000", label: "$390.000" },
    { value: "400000", label: "$400.000" },
    { value: "400001", label: "Más de $400.000" }
  ],

  activeUniversities: [
    {
      'code': 'uss',
      'name': 'U. San Sebastián',
      'registrationMessage': 'El Centro de Egresados de la Facultad de Derecho y Ciencias Sociales de la Universidad San Sebastián ha establecido un convenio con Work on Law, sitio web exclusivo con ofertas laborales para abogados y estudiantes de derecho con el objetivo de mejorar y potenciar su empleabilidad. Ingresa aquí y revisa las opciones laborales',
      'jobListMessage': 'El Centro de Egresados de la Facultad de Derecho y Ciencias Sociales de la Universidad San Sebastián ha establecido un convenio con Work on Law, sitio web exclusivo con ofertas laborales para abogados y estudiantes de derecho con el objetivo de mejorar y potenciar su empleabilidad.',
      'logo': 'https://wol-logos.s3.amazonaws.com/university-uss.png'
    }
    // ,
    // {
    //   'code': 'uai',
    //   'name': 'U. Adolfo Ibáñez (Santiago)',
    //   'registrationMessage': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec posuere ante vitae erat dapibus mollis. Vivamus semper massa quis mi ultrices, vitae vestibulum ligula commodo. Donec consectetur, sem vitae elementum interdum, nulla nisi ultricies nibh, et laoreet turpis diam eu nunc',
    //   'jobListMessage': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec posuere ante vitae erat dapibus mollis. Vivamus semper massa quis mi ultrices, vitae vestibulum ligula commodo. Donec consectetur, sem vitae elementum interdum, nulla nisi ultricies nibh, et laoreet turpis diam eu nunc',
    //   'logo': 'https://wol-logos.s3.amazonaws.com/university-uss.png'
    // },
    // {
    //   'code': 'uai',
    //   'name': 'U. Adolfo Ibáñez (Viña)',
    //   'registrationMessage': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec posuere ante vitae erat dapibus mollis. Vivamus semper massa quis mi ultrices, vitae vestibulum ligula commodo. Donec consectetur, sem vitae elementum interdum, nulla nisi ultricies nibh, et laoreet turpis diam eu nunc',
    //   'jobListMessage': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec posuere ante vitae erat dapibus mollis. Vivamus semper massa quis mi ultrices, vitae vestibulum ligula commodo. Donec consectetur, sem vitae elementum interdum, nulla nisi ultricies nibh, et laoreet turpis diam eu nunc',
    //   'logo': 'https://wol-logos.s3.amazonaws.com/university-uss.png'
    // }
  ]

}

export default wolData;