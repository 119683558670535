import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap'
import { Formik } from 'formik';
import Select from 'react-select';
import ReactGA from 'react-ga';
import mixpanel from 'mixpanel-browser';
//import * as Yup from 'yup';

import wolData from '../../helpers/wolData';
import utils from '../../helpers/utils';
import WolSpinner from '../commons/WolSpinner';

const getInitialValues = (data) => {
  const inputs = {}
  for (let [index, item] of data.entries()) {
    inputs[`start_date_${index}`] = item.start_date;
    inputs[`end_date_${index}`] = item.end_date;
    inputs[`description_${index}`] = item.description;
    inputs[`position_${index}`] = '';
  }
  return(inputs)
}

const handleCvSubmiting = (setData) => (values, actions) => {
  // Puedes usar el método y los valores del formulario aquí

  let data = new FormData();
  if (values.cv && values.cv.name) { data.append("cv", values.cv) }

  const requestOptions = {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('jwt')
    },
    body: data
  };

  const url = wolData.urls.APIUrl + "/candidates/upload_cv"

  fetch(url, requestOptions)
    .then((response) => {
      return response.json()
    })
    .then((data) => {
      if (data.errors) {
        if (data.errors.invalid_token) {
          localStorage.removeItem('jwt');
          window.location.href = wolData.urls.frontUrl + "/sign-in";
        }

        ReactGA.event({ category: 'candidate', action: 'cv-upload-error' });

      } else {

        setData(data)

        // Tracking
        ReactGA.event({ category: 'candidate', action: 'cv-upload-success' });

        // Mixpanel
        mixpanel.track('CV successfuly uploaded')
      }
    })
    .catch((errors) => {
      console.error(errors)
    });
};

const UploadCvForm = ({ data, setData, option }) => {

  if(
    !(data) ||
    !(data.status) ||
    ((data.status != 'inference_not_found') && option !== 'new')
  ) {

    return null;
  }

  return (
    <Row>
      <Col>

        <h2>
          Sube tu CV
        </h2>

        <Alert variant='info' className='mt-4'>
          Intentaremos leer tu CV para ahorrarte algo de tiempo ingresando la información de tu perfil
        </Alert>

        <Formik
          initialValues={{ cv: '' }}
          onSubmit={handleCvSubmiting( setData )}
        >
          {({
            errors,
            status,
            touched,
            setFieldValue,
            handleBlur,
            handleSubmit }
          ) => (

            <Form onSubmit={handleSubmit} className='wol-form'>
              {/* CV */}
              <Form.Group className="form-group-custom">
                {/* <Form.Label>
                  CV *<br></br>
                </Form.Label> */}
                <Form.File
                  name="cv"
                  type="file"
                  onBlur={handleBlur}
                  className={utils.fieldClassName('cv', status, touched, errors)}
                  onChange={(event) => {
                    setFieldValue("cv", event.currentTarget.files[0]);
                  }}
                />
                {/* {utils.displayFileData(cvInitialValues)} */}
                {utils.displayFieldError('cv', status, touched, errors)}
              </Form.Group>

              <Button variant="danger" type="submit">
                Cargar CV
              </Button>

            </Form>

          )
          }
        </Formik>

      </Col>
    </Row>
  )
}

const WorkHistoryMessage = ({ data }) => {
  if (
    !(data) ||
    !(data.status) ||
    !(['requested', 'failed'].includes(data.status))
  ) {

    return null;
  }

  const messagesMap = {
    'requested': 'Obteniendo información ...',
    'failed': 'Ocurrió un problema leyendo CV'
  }

  return (
    <Row className='mt-4'>
      <Col>
        <h2>Sube tu CV</h2>
        <Alert variant='info' className='mt-5'>
          { messagesMap[data.status] }
        </Alert>
      </Col>
    </Row>
  );
}

const WorkHistoryForm = ({ data }) => {
  if( !(data) ){ return null };

  return (
    <Row className='mb-5'>
      <Col>
        <h2>Experiencia profesional</h2>

        <Button variant='outline-danger' className='mt-4' href='/work-history-from-cv?option=new' >
          Cargar nuevo CV
        </Button>

        <Formik
          initialValues={getInitialValues(data)}
          onSubmit={() => { }}>
          {({
            values,
            errors,
            status,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue }) => {

            return (
              <Row className='mt-4'>
                <Col>
                  <Form onSubmit={handleSubmit}>
                    {
                      data.map((work, index) => {
                        return (
                          <WorkItem
                            data={work}
                            index={index}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            values={values}
                            status={status}
                            touched={touched}
                            errors={errors}
                            setFieldValue={setFieldValue}
                          />
                        )
                      })
                    }

                    <div className='edit-form-options'>
                      <Button variant="danger" type="submit" disabled>
                        Guardar
                      </Button>
                    </div>
                  </Form>
                </Col>
              </Row>
            )
          }}
        </Formik>
      </Col>
    </Row>


  )
}

const WorkItem = ({
  data,
  index,
  handleChange,
  handleBlur,
  values,
  status,
  touched,
  errors,
  setFieldValue
}) => {

  // TO DO:
  // - Selector de posición

  return(
    <Row className='mt-5'>
      <Col>


        <Row className='pt-2' style={{backgroundColor: '#F4F7FF'}}>
          <Col>
            <strong>
              <h3>
                {`${data.position_name} - ${data.employer_name}`}
              </h3>
            </strong>
          </Col>
        </Row>

        {/* Position */}
        <Row className='mt-3'>
          <Col>
            <Form.Group className="form-group-custom">
              <Form.Label>Cargo *</Form.Label>
              <Form.Control
                name={`position_${index}`}
                as="select"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values[`position_${index}`]}
                className={utils.fieldClassName(`position_${index}`, status, touched, errors)}
              >
                {wolData.positions.map((p) => (
                  <option key={p.value} value={p.value}>
                    {p.label}
                  </option>
                ))}
              </Form.Control>
              {utils.displayFieldError(`position_${index}`, status, touched, errors)}
            </Form.Group>
          </Col>
        </Row>

        {/* Start date */}
        <Row className='mt-3'>
          <Col>
            <Form.Group className="form-group-custom">
              <Form.Label>Fecha de inicio *</Form.Label>
              <Form.Control
                name={`start_date_${index}`}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values[`start_date_${index}`]}
                type="date"
                className={utils.fieldClassName(`start_date_${index}`, status, touched, errors)}
              >
              </Form.Control>
              {utils.displayFieldError(`start_date_${index}`, status, touched, errors)}
            </Form.Group>
          </Col>

          {/* End date */}
          <Col>
            <Form.Group className="form-group-custom">
              <Form.Label>
                Fecha de término
              </Form.Label>
              <Form.Control
                name={`end_date_${index}`}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values[`end_date_${index}`]}
                type="date"
                className={utils.fieldClassName(`end_date_${index}`, status, touched, errors)}
              >
              </Form.Control>
              {utils.displayFieldError('end_date', status, touched, errors)}
              <small>Este campo debe quedar en blanco si aún te encuentras trabajando en esta posición</small>
            </Form.Group>
          </Col>
        </Row>

        {/* Workplace */}
        {/*defaultValue={values.workplace_id} */}
        <Row className='mt-3'>
          <Col>
            <Form.Group className="form-group-custom">
              <Form.Label>Lugar de trabajo *</Form.Label>
              <Select
                name={`workplace_id`}
                options={data.suggested_workplaces}
                className={utils.fieldClassName('workplace_id', status, touched, errors)}
                onChange={(option) => { setFieldValue("workplace_id", option) }}
              />
              {utils.displayFieldError('workplace_id', status, touched, errors)}
            </Form.Group>

          </Col>
        </Row>

        {/* Description */}
        <Row className='mt-3'>
          <Col>
            <Form.Group>
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                name={`description_${index}`}
                as='textarea'
                maxLength='800'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values[`description_${index}`]}
                className={utils.fieldClassName(`description_${index}`, status, touched, errors)}
              ></Form.Control>
              {
                (values[`description_${index}`]) ?
                  <small style={{ display: 'flex', justifyContent: 'flex-end' }}>{values[`description_${index}`].length}/400</small> : null
              }
              {utils.displayFieldError(`description_${index}`, status, touched, errors)}
            </Form.Group>
          </Col>
        </Row>

      </Col>
    </Row>
  )
}

const WorkHistoryFromCv = ( { option } ) => {

  const [data, setData] = useState(null)

  useEffect(() => {
    const endpointUrl = `${wolData.urls.APIUrl}/candidates/work_history_from_cv`;
    const request = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('jwt')
      }
    }

    fetch( endpointUrl, request ).then((response) => {
      return response.json()

    }).then((responseData) => {
      if (responseData.errors) {
        if (responseData.errors.invalid_token) {
          localStorage.removeItem('jwt');
          window.location.href = wolData.urls.frontUrl + "/sign-in";
        }
      } else {
        setData(responseData)
      }
    }).catch((errors) => {
      console.log(errors)
    })
  }, []);

  // Render Loader
  if (!(data)) { return( <WolSpinner /> ) }

  // Render Upload CV Form
  const forceNewInference = ( (option) && (option === 'new') );
  console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
  console.log(forceNewInference)
  console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
  const inferenceNotFound = ( (data) && (data.status) && (data.status === 'inference_not_found') );
  if( forceNewInference || inferenceNotFound) { return( <UploadCvForm data={ data } setData={ setData } option={option} /> ) };

  // Render message
  const loadingCv = ( ['requested', 'failed'].includes(data.status) );
  if(loadingCv){ return( <WorkHistoryMessage data={data} /> ) };

  // Render Work History
  const showHistory = (data) && (data.status == 'processed' );
  if(showHistory){ return( <WorkHistoryForm data={ data.history } /> ) };

  return( <h2>Error</h2> );
}

export default WorkHistoryFromCv;
