import { Row, Col, Tabs, Tab } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';

import ProfileForm from '../candidateProfile/ProfileForm';
import ProfileForm2 from '../candidateProfile2/ProfileForm2';
import ProfileForm3 from '../candidateProfile3/ProfileForm3';
import CriteriaProfile from "../candidateProfile4/CriteriaProfile";

const Profile = () => {
  const { t } = useTranslation("global");
  const candidateFullName = localStorage.getItem('userName') + " " + localStorage.getItem('userSurname');

  return (
    <div id="profile-container" className='wol-container mb-5'>
      <Row>
        <Col>
          <Row className='mb-4'>
            <Col><h2>Perfil de {candidateFullName}</h2></Col>
          </Row>
          <Row>
            <Col>
              <Tabs id="profile-nav-links-container" defaultActiveKey="first">
                <Tab eventKey="first" title="Formación académica">
                  <ProfileForm />
                </Tab>
                <Tab eventKey="second" title={t('CandidateProfile.profile2LinkText')}>
                  <ProfileForm2 formFlow={'profile'} />
                </Tab>
                <Tab eventKey="third" title="Experiencia profesional">
                  <ProfileForm3 />
                </Tab>
                <Tab eventKey="fourth" title="Pruebas psicolaborales">
                  <CriteriaProfile />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default Profile;