import { Badge } from 'react-bootstrap';

type Options = {
  variant: string;
  fontSize: string;
}

const WorkExperienceIcon = ({ experienceYears, options }: { experienceYears: string, options: Options }) => {
  let variant = 'warning'
  let fontSize = '18px'
  if (options) {
    if (options.variant) {
      variant = options.variant;
    }
    if (options.fontSize) {
      fontSize = options.fontSize;
    }
  }

  return <Badge pill style={{ fontSize: fontSize }} variant={variant}>{experienceYears}</Badge>;
}

export default WorkExperienceIcon;