import { useState } from "react";
import wolData from "../../helpers/wolData";
import EditForm from "./EditForm";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { MdDelete, MdEdit, MdInfo } from "react-icons/md";

const dateFormat = (dateStr) => {
  let aux = dateStr.split(/\D/g);
  return [aux[2], aux[1], aux[0]].join("-");
};

const JobItem = ({ item, setWorkExperienceYear}) => {
  const [job, setJob] = useState(item);
  const [show, setShow] = useState(true);
  const [showEditForm, setShowEditForm] = useState(false);

  const deleteData = async () => {
    try {
      const confirmation = window.confirm('¿Estás seguro de querer eliminar este trabajo?');
      if (confirmation) {
        const url = wolData.urls.APIUrl + "/candidates/" + localStorage.getItem("candidateId") + "/positions/" + job.id + '/destroy';
        const response = await fetch(url, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('jwt')
          }
        })
        const data = await response.json()

        if (data.errors) {
          if (data.errors.invalid_token) {
            localStorage.removeItem('jwt');
            window.location.href = wolData.urls.frontUrl + "/sign-in";
          }
        }
        else {
          setWorkExperienceYear(data.work_experience_years_label)
          setShow(false)
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const editData = () => {
    setShowEditForm(prev => !prev)
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Este cargo no es considerado en el cálculo de la experiencia profesional
    </Tooltip>
  );

  return show ? (
    <div className={'job-item'}>
      <div className={'main-info'}>
        {wolData['positions'][job.position].label}
        <small > {job.suggested_workplace_name} | { job.start_date ? dateFormat(job.start_date) : ''} - {job.end_date ? dateFormat(job.end_date) : 'a la fecha'}</small>
      </div>
      <small style={{wordBreak: 'break-all'}}>{job.description}</small>
      <div className="options">
        {job.position <= 4 &&
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 0 }}
            overlay={renderTooltip}>
            <MdInfo className='icon'/>
          </OverlayTrigger>}
        <MdDelete className='icon' onClick={() => deleteData()} />
        <MdEdit className='icon' onClick={() => editData()} />
      </div>
      {showEditForm ? <EditForm job={job} setJob={setJob} show={editData} setWorkExperienceYear={setWorkExperienceYear} /> : null}
    </div>
  ) : null
}

export default JobItem;